.buttonsBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
}

.buttonLink {
    margin-top: 11px;
}

.simpleCard {
    margin-bottom: 20px;
    min-height: 113px;
    display: flex;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.simpleCard:last-child {
    margin-bottom: 0;
}

.simpleCardImage {
    height: 101px;
}

.simpleCardInfo {
    text-align: left;
    padding: 13px 20px 0 13px;
}

.notAvailable {
    color: var(--col--utilityError);
    margin-top: 5px;
}

@media (max-width: 960px) {
    .simpleCardImage {
        height: 85px;
    }

    .simpleCard {
        min-height: 95px;
    }

    .buttonsBlock {
        flex-direction: row;
        padding-bottom: 0;
        filter: drop-shadow(0px -5px 15px rgba(0, 0, 0, 0.15));
    }

    hr.divider {
        height: 60px;
        background-color: var(--col--light);
    }

    .button {
        height: 60px;
        padding: 0 16px;
        border-radius: 0;
    }

    .notAvailable {
        margin-top: 0px;
    }
}
