.linkButton {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.linkButton:hover {
    text-decoration-line: none;
}
