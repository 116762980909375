.button {
    composes: button from './button.vars.css';
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--ff--button);
    font-weight: var(--button-font-weight);
    box-shadow: var(--button-box-shadow);
    border-radius: var(--button-border-radius);
    font-size: var(--button-font-size);
    line-height: var(--button-line-height);
    padding: var(--button-padding);
    cursor: pointer;
    color: var(--button-color);
    border: var(--button-border);
    background-color: var(--button-background-color);
    min-height: 50px;
    text-transform: var(--button-text-transform);
    letter-spacing: var(--button-letter-spacing);
}

.button:focus:not(:focus-visible) {
    outline: none;
}

.fullWidth {
    width: 100%;
}

.button:disabled {
    background-color: var(--col--gray4);
    border-color: var(--col--gray4);
    cursor: not-allowed;
    color: var(--button-disabled-color);
}

.button:disabled a {
    cursor: not-allowed;
    pointer-events: none;
}

.button:hover:not(:disabled) {
    background-color: var(--button-hover-background-color);
    border-color: var(--button-hover-border-color);
}

.link {
    display: var(--button-link-display, inline-flex);
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: var(--ff--button);
    text-decoration: none;
    text-transform: var(--link-text-transform);
}

.large {
    font-size: var(--button-large-font-size);
    line-height: var(--button-large-line-height);
    letter-spacing: var(--button-large-letter-spacing);
    min-height: 68px;
    border-radius: var(--button-large-border-radius);
    padding: var(--button-large-padding);
}

.small {
    font-size: var(--button-small-font-size);
    line-height: var(--button-small-line-height);
    padding: var(--button-small-padding);
    min-height: 44px;
}

.button.secondary {
    color: var(--button-secondary-color);
    background-color: var(--button-secondary-background-color);
    border-color: var(--button-secondary-color);
}

.button.secondary:disabled {
    color: var(--col--gray4);
    border-color: var(--col--gray4);
}

.button.secondary:hover:not(:disabled) {
    color: var(--button-secondary-hover-color);
    background-color: var(--button-secondary-hover-background-color);
    border-color: var(--button-secondary-hover-border-color);
}

.button.disabled {
    background-color: var(--col--gray4);
    border-color: var(--col--gray4);
    color: var(--button-disabled-color);
    pointer-events: none;
}

.willChangeTransform {
    will-change: transform;
}
