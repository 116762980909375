/* link default styles */
.link {
    font-family: var(--ff--link);
    text-decoration: none;
    color: inherit;
}

.linkSecondaryActive,
.linkPrimaryActive {
    composes: link from './link.vars.css';
    font-style: normal;
    font-weight: var(--link-active-font-weigth);
    text-decoration-line: underline;
    text-underline-offset: var(--link-text-underline-offset);
    text-transform: var(--text-transform);
    cursor: pointer;
}

.linkSecondaryActive {
    font-size: var(--link-secondary-active-font-size);
    line-height: var(--link-secondary-active-line-height);
    display: var(--linkButton-link-secondary-active-display);
}

.linkPrimaryActive {
    font-size: 18px;
    line-height: var(--link-primary-active-line-height);
}

@media screen and (max-width: 960px) {
    .linkPrimaryActive {
        font-size: 14px;
        line-height: 18px;
    }
}
