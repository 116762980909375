.modalContainer {
    top: 85px;
    display: flex;
    flex-direction: column;
    height: 701px;
    width: 550px;
    margin: 0 auto;
    padding: 40px 40px 30px;
    outline: none;
    position: relative;
    background-color: var(--col--light);
    box-sizing: border-box;
    text-align: center;
}

.titleSection {
    padding-top: 25px;
}

.subtitle {
    padding: 10px 0 24px;
}

.footerText {
    color: var(--col--gray6);
    padding: 11px 30px 30px;
}

.closeButton {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    color: var(--col--dark);
}

.contentSection {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.itemsBlock {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 4px;
}

.warning {
    color: var(--col--utilityError);
}

@media (max-width: 960px) {
    .modalContainer {
        top: 0;
        width: 100%;
        height: var(--app-height);
        padding: 30px 0 0;
    }

    .contentSection {
        height: 100%;
        padding: 0 20px;
    }

    .footerText {
        color: var(--col--gray6);
        padding: 27.5px 30px 30px;
    }

    .itemsBlock {
        padding: 4px 10px;
    }
}
