.iconContainer {
    font-size: 24px;
}

.huge {
    font-size: 76px;
}

.xl {
    font-size: 60px;
}

.l {
    font-size: 44px;
}

.m {
    font-size: 24px;
}

.s {
    font-size: 18px;
}

.xs {
    font-size: 14px;
}

.tiny {
    font-size: 10px;
}

.brand-icon::before {
    color: var(--col--dark);
}

.light::before {
    color: var(--col--light);
}

.dark::before {
    color: var(--col--dark);
}

.gray6::before {
    color: var(--col--gray6);
}

.colorful::before {
    color: var(--col--primary1);
}

.gray4::before {
    color: var(--col--gray4);
}
