html,
body {
    margin: 0;
    padding: 0;
    font-family: var(--base-font);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--col--dark);
    background-color: var(--col--light);
}

@media screen and (min-width: 960px) {
    html,
    body {
        font-size: 18px;
        line-height: 28px;
    }
}

/* TODO describe this and how to use global styles in CONVENTIONS */

/* This typography styles are DEPRECATED. Please do NOT modify them as it could affect many places in the application.
When implementing new components or modifying the existing ones, don't use this styles as we're trying to get rid of
them. Please consider:
- For paragraphs and headers use styles in typography.css
- For lists use styles in lists.css
- For links use InspireLink component */

a {
    font-family: var(--link-font);
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
}

#__next {
    height: 100vh;
}

#main {
    max-width: var(--desktop-width);
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#main footer {
    margin-top: auto;
}

.sticky-top {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 999;
}

.web-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 85px;
    background-color: var(--col--dark);
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: white;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.richText > * {
    list-style: none;
    padding: 0px;
}

.menu-item-subcategory {
    color: var(--col--primary1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/** Typography */

p {
    margin: 0;
}

/** Lists */
ul {
    list-style: none;
}

ul li {
    position: relative;
    padding-left: 1em;
}

ul li::before {
    content: '\2022';
    color: var(--col--primary1);
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    position: absolute;
    left: 0;
    width: 1em;
}

ol {
    list-style: none;
    counter-reset: li;
}

ol li {
    counter-increment: li;
    position: relative;
    padding-left: 1em;
}

ol li::before {
    content: counter(li);
    color: var(--col--primary1);
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    position: absolute;
    left: 0;
    width: 1em;
}

ul li,
ol li {
    font-size: var(--li-md-size, 16px);
    line-height: var(--li-md-line-height, 24px);
}

@media screen and (min-width: 960px) {
    ul li,
    ol li {
        font-size: var(--li-lg-size, 18px);
        line-height: var(--li-lf-line-height, 28px);
    }
}

/** Links */

.link-secondary-active,
.link-primary-active {
    font-family: var(--ff--link);
    font-style: normal;
    font-weight: normal;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
}

.link-secondary-active {
    font-size: 14px;
    line-height: 18px;
}

.link-primary-active {
    font-size: 18px;
    line-height: 22px;
}

@media screen and (max-width: 960px) {
    .link-primary-active {
        font-size: 14px;
        line-height: 18px;
    }
}

/* Truncate */

/* single line */
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* multi line */
.truncate-at-2,
.truncate-at-3,
.truncate-at-4,
.truncate-at-5 {
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-at-2 {
    -webkit-line-clamp: 2;
}

.truncate-at-3 {
    -webkit-line-clamp: 3;
}

.truncate-at-4 {
    -webkit-line-clamp: 4;
}

.truncate-at-5 {
    -webkit-line-clamp: 5;
}

_:-ms-fullscreen,
:root .truncate-at-2,
.truncate-at-3,
.truncate-at-4,
.truncate-at-5 {
    white-space: nowrap;
}

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
