.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes loaderAnimation {
    from {
        height: 100%;
    }

    to {
        height: 0;
    }
}

.icons {
    position: relative;
}

.icon::before {
    color: var(--col--primary1);
}

/*
    Fix overriding by BrandIcon styles
    BrandIcon styles are loaded twice: in __app chunk and a page chunk
*/
.container .icon {
    font-size: 105px;
}

.icon:last-child::before {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    color: var(--col--dark);
    animation-name: loaderAnimation;
    animation-duration: 3s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
