.modalContainer {
    top: 50%;
    transform: translateY(-60%);
    display: flex;
    flex-direction: column;
    height: auto;
    width: 936px;
    margin: 0 auto;
    outline: none;
    position: relative;
    background-color: var(--col--light);
    box-sizing: border-box;
    text-align: center;
}

.content {
    padding: 40px 40px 30px;
}

.closeButton {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    color: var(--col--dark);
}

.description {
    padding: 8px 0 24px;
}

.contentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ctaSection {
    padding: 24px 0;
}

.cta {
    margin: 0 12px;
}

.disclaimerTogglerText {
    cursor: pointer;
    text-decoration-line: underline;
    color: var(--col--dark);
}

.disclaimer {
    padding: 4px;
}

@media (max-width: 960px) {
    .modalContainer {
        width: 90%;
    }

    .cta {
        margin: 0 0 16px;
        width: 100%;
    }

    .cta:last-child {
        margin-bottom: 0;
    }

    .content {
        padding: 40px 20px 20px;
    }
}
