.button {
    /* primary */
    --button-font-size: 16px;
    --button-line-height: 16px;
    --button-padding: 0 28px;
    --button-color: var(--col--light);
    --button-border: 4px solid var(--col--primary1);
    --button-background-color: var(--col--primary1);
    --button-letter-spacing: 0.01em;
    --button-border-radius: 0px;
    --button-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
    --button-font-weight: 800;

    /* hover */
    --button-hover-background-color: var(--col--primary1variant);
    --button-hover-border-color: var(--col--primary1variant);

    /* disabled */
    --button-disabled-color: var(--button-color);

    /* large */
    --button-large-font-size: 22px;
    --button-large-line-height: 22px;
    --button-large-letter-spacing: 'none';
    --button-large-border-radius: 0px;
    --button-large-padding: 0 28px;

    /* small */
    --button-small-font-size: 22px;
    --button-small-line-height: 22px;
    --button-small-padding: 0px 16px;

    /* secondary */
    --button-secondary-color: var(--col--primary1);
    --button-secondary-background-color: var(--col--light);

    /* secondary hover */
    --button-secondary-hover-color: var(--col--primary1variant);
    --button-secondary-hover-background-color: var(--col--light);
    --button-secondary-hover-border-color: var(--button-secondary-hover-color);

    /* button text transform */
    --button-text-transform: uppercase;

    /* link text transform */
    --link-text-transform: uppercase;
}
