.container {
    display: inline-block;
    position: relative;
    width: 100%;
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.backgroundImageObjectFit {
    object-fit: cover;
}
